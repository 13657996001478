/* ----global css start----------- */
.icon{
    font-size: 18px;
}
.long_arrow{
    font-weight: 600;
    font-size: 20px;
}
/* ----global css end----------- */




/*bg de contenedor de video*/
.bgCountentVideo {
    position: relative;
    padding: 100px 0 0;
    margin-bottom: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    border-bottom-left-radius: 100% 80%;
    border-bottom-right-radius: 100% 80%;
  }
  
  .bgCountentVideo .info {
    width: 60%;
    margin: 0 auto -80px;
  }
  
  .bgCountentVideo .countentVideoInfo {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .bgCountent::before,
  .bgFormHome::before,
  .bgCountentVideo::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .bgCountentVideo::before {
    border-bottom-left-radius: 100% 80%;
    border-bottom-right-radius: 100% 80%;
    background-color: var(--overlay-video-content);
  }
  
  .bgCountent::before,
  .bgFormHome::before {
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  
  
  @media screen and (max-width:569px) {
    .bgCountentVideo {
      margin-bottom: 100px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  
    .bgCountentVideo::before{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .bgCountentVideo .info {
      width: 90%;
      margin: 0 auto -30px;
    }
    .video_resourse{
        width: 90%;
        height: 150px !important;
        top: 50px;
        margin-top: -80px !important;
        height: 100%;
    }
  }

.video_resourse{
    width: 60%;
    height: 510px;
    margin: auto;
    border-radius: 20px;
    position: relative;
    top: 150px;
}